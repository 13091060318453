<template>
  <form class="form">
    <fieldset class="form__fieldset">
      <div class="form__flex">
        <div class="row">
          <div class="col-3">
            <FveFileImagePreview
              label=""
              name="avatar"
              v-model="form.avatar"
            />
          </div>
          <div class="col-9">
            <div class="row">
              <div class="col-4">
                <FveText
                  label="Фамилия"
                  name="last_name"
                  required
                  v-model="form.last_name"
                />
              </div>
              <div class="col-4">
                <FveText
                  label="Имя"
                  name="first_name"
                  required
                  v-model="form.first_name"
                />
              </div>
              <div class="col-4">
                <FveText
                  label="Отчество"
                  name="middle_name"
                  v-model="form.middle_name"
                />
              </div>
              <div class="col-4">
                <FveSelectValue

                  v-if="stateList"
                  :option-list="stateList"
                  :option-get-key="(obj)=>{ return obj.key.toString(); }"
                  :option-get-name="(obj)=>{ return obj.value.toString(); }"

                  label="Статус"
                  name="state"
                  required
                  v-model="form.state"
                />
              </div>
              <div class="col-4">
                <FveDatePicker
                  label="Дата рождения"
                  name="birthday"
                  v-model="form.birthday"
                />
              </div>
              <div class="col-4">
                <div class="btn-gp" style="padding-top: 24px;">
                  <b-button size="lg" variant="danger" @click="formSubmit">Сохранить</b-button>
                  <b-button size="lg" variant="link" @click="$emit('cancel')">Отмена</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </fieldset>

  </form>
</template>

<script>

import FveText from "@widgetFormValidate/src/Element/Text/FveText";
import FveFileImagePreview from "@widgetFormValidate/src/Element/Text/FveFileImagePreview";
import FveSelectValue from "@widgetFormValidate/src/Element/Select/vue-multiselect/FveSelectValue";
import FveDatePicker from "@widgetFormValidate/src/Element/Date/FveDatePicker";

//
import FveFormMixin from "@widgetFormValidate/src/Mixin/FveFormMixin";

export default {
  mixins: [
    FveFormMixin
  ],
  components: {
    FveText,
    FveSelectValue,
    FveDatePicker,
    FveFileImagePreview,
  },
  data(){
    return {
      stateList: false,
    };
  },
  methods: {
    formSchema() {
      return {
        //
        avatar       : { type: FileClass, default: () => { return new FileClass({}); } },
        //
        last_name    : { type: String, default: () => { return ''; } },
        first_name   : { type: String, default: () => { return ''; } },
        middle_name  : { type: String, default: () => { return ''; } },
        //
        state        : { type: String, default: () => { return ''; } },
        birthday     : { type: String, default: () => { return ''; } },
      };
    },
  },
  mounted() {
    RequestManager.Client.getClientFamilyStates({}).then((results) => {
      this.stateList = results;
    });
  }
};

</script>

<style lang="scss" scoped>
</style>
