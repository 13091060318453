<template>
  <ClientEditTemplate
    add="Добавить члена семьи"
    @clickAdd="viewAddForm"
  >

    <div class="panel-base" v-if="formView">
      <ClientEditFamilyForm
        :formData="formData"
        @cancel="closeForm"
        @submit="submitForm"
      />
    </div>

    <div class="row">
      <template v-for="(item, index) in clientFamilyList">
        <div class="col-4 mb-md" :key="index">
          <CardFamily
            :img="item.avatar.toString()"
            :name="`${item.last_name} ${item.first_name} ${item.middle_name}`"
            :date="item.birthday"
            :status="item.readable_state"

            @edit="viewEditForm(item, index)"
            @remove="remove(item, index)"
          />
        </div>
      </template>
    </div>

  </ClientEditTemplate>
</template>

<script>

import ClientEditTemplate from "@page/Client/_component/ClientEditTemplate";

import ClientEditFamilyForm from "@component/Form/ClientEditFamilyForm";
import CardFamily from "@component/Module/CardFamily";

export default {
  name: "ClientEditFamily",
  components: {
    ClientEditTemplate,
    ClientEditFamilyForm,
    CardFamily,
  },
  props: {
    clientId: [String, Number]
  },
  data(){
    return this.getDefaultData();
  },
  methods:{
    getDefaultData() {
      return {
        formView: false,
        formData: {},
        clientFamilyList: [],
      };
    },
    load(){
      RequestManager.Client.getClientFamilyById({
        id: this.clientId
      }).then((res) => {
        this.clientFamilyList = res.results;
      });
    },
    reload(){
      Object.assign(this, this.getDefaultData());
      this.load();
    },
    viewAddForm(){
      this.formView = true;
      this.formData = {};
    },
    viewEditForm(item, index){
      this.formView = true;
      this.formData = Object.assign({index: index}, item);
    },
    closeForm(){
      this.formView = false;
      this.formData = {};
    },
    submitForm(data){
      if(this.formData.id) {
        // edit
        RequestManager.Client.updateClientFamily({id: this.clientId, familyId: this.formData.id, user: data}).then(this.reload);
      } else {
        // add
        RequestManager.Client.addClientFamily({id: this.clientId, user: data}).then(this.reload);
      }

    },
    remove(item, index){
      const options = {title: 'Удалить члена семьи?', cancelLabel: 'Нет', okLabel: 'Да'};
      this.$dialogs.confirm('Удалить члена семьи?', options)
        .then((res) => { // {ok: true|false|undefined}
          if(res && res.ok) {
            RequestManager.Client.deleteClientFamily({id: this.clientId, familyId: item.id}).then(this.reload);
          }
        });
    }
  },
  mounted() {
    RequestManager.Client.getClientFamilyById({
      id: this.clientId
    }).then((res) => {
      this.clientFamilyList = res.results;
    });
  }
};
</script>

<style lang="scss" scoped>

</style>
